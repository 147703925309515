.appBar {
  position: relative;
  left: 0%;
  display: flex;
  top: 0;
  /* transform: translate(-50%); */
  z-index: 100000;
  border-radius: 0px!important;
  border: 0px solid #cad2d3;
  overflow: auto;
  box-shadow: 0 2px 3px rgba(0,0,0,.3)!important;
  background-color: #fff;
}

#root > div.appBar > div > div.appBarContent.noselect > div.sliderContainer > div{
      width: 100%!important;
    margin: auto!important;
    OVERFLOW: visible!important;
}

.appBar > div{
  width: 100%;
}
.rc-slider-mark-text{
  font-size: 8px;
}

.appBarTitle {
  padding: 14px;
  padding-top: 0px;
  background-color: #ffffff;
  border-bottom: 1px solid #e4e8e5;
  text-align: center;
  color: #519626;
  font-size: 2.5em;
  /* text-align: end; */
  font-family: revert;
  font-feature-settings: "pcap", "c2pc";
  font-variant: all-petite-caps;
  font-weight: 100;
}

.appBarContent {
  font-size: 22px;
  background-color: #c9d2d340;
  margin-top: 0px;
  padding-top: 1px;
}

.trends {
  position: fixed;
  left: 50%;
  display: flex;
  top: 183px;
  transform: translate(-50%, 0px);
  z-index: 100000;
  border-radius: 2px !important;
  border: 1px solid white;

  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3) !important;
  background-color: white;
  font-size: 12px;
  padding: 15px;
}

.sliderContainer {
  margin: 16px 40px 4px 40px;
  height: 40px;
      overflow: auto;
}

.fromVersion {
  display: inline;
  left: 0px;
}

.toVersion {
  position: absolute;
  display: inline;
  right: 10px;
}

.footerBar {
  position: fixed;
  left: 50%;
  z-index: 99999999;
  top: 185px;
  border-radius: 2px !important;
  border: 1px solid #639c4b;

  transform: translate(-50%, 0px);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3) !important;
}

.footerBarTitle {
  background-color: #649b4c;
  border-right: 1px solid white;
  text-align: center;
  padding: 6px;
  font-size: 15px;
  color: white;
  font-variant: all-petite-caps;
  font-family: revert;
}

.footerBarContent {
  padding: 2px 5px;
  font-size: 10px;
  background-color: white;
  white-space: nowrap;
}

.map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.mapboxgl-popup-content {
  padding: 0px !important;
  border-radius: 3px !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3) !important;

  cursor: pointer;
}

.mapboxgl-popup-tip {
  display: none !important;
}
.logoImg{
  width: 257px;
  margin-bottom: -60px;
  margin-left: 10px;
  margin-top: 28px;
}
.appBar > div > div:nth-child(1){
  margin-top: -20px;
}
.countryPopup .name {
  padding: 1px 8px;
  background: #ced8d8;
  min-width: 40px;
  border-bottom: 1px solid #b8c5c5;
  color: #687071;
}

.countryPopup .stats {
  padding: 2px 4px;
  text-align: center;
  background-color: #889798;
  color: white;
}

.statusDiv {
  display: inline;
}

.stat {
  font-size: 14px;
}

.total {}

.added {
  color: green;
}

.removed {
  color: #a25b28;
}

.changed {
  color: #b7c9d1;
  font-size: 10px;
}

.no_change {
  color: #b7c9d1;
}
.paPopupContent {
  padding: 10px;
}
#popup {
  position: absolute;
  background-color: #9baaac;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgb(0 0 0 / 30%);

  color: white;
}
.rt-thead.-header{
  min-width: 500px!important;
}
.ReactTable .rt-thead.-header {
  box-shadow: none!important;
  border-bottom: 2px solid #8b999a;
  font-weight: 700;
}
.ReactTable .rt-table {
  flex: auto 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
  font-size: 10px!important;
}
.wdpaPopup {
  font-size: 12px;
}

.wdpaPopup table {
  border-spacing: 0px;
}

.wdpaPopup table td {
  padding: 2px;
}

.PAPopup {
  z-index: 3;
}

.PAPopupList {
  z-index: 2;
}

.paPopupListHeader {
  background: whitesmoke;
  padding: 3px;
}

.wdpaPopupListItem:hover {
  background-color: whitesmoke;
}

.wdpaPopup a {
  color: unset;
  text-decoration: none;
}

.paPopupContent {
  padding: 10px;
}

.paPopupChangeType {}

.paPopupName {
  padding: 10px;
  background-color: #dfe7e8;
  border-bottom: 1px solid #9baaac;
  font-weight: 500;
  color: #819092;
}

.paPopupRow {
  padding: 3px;
}

.paPopupNameLeft {}

.ppLink {
  padding-left: 8px;
  float: right;
  font-size: 10px;
}

.underline {
  cursor: pointer;
  text-decoration: underline;
}

.paPopupHeaderRow {
  font-style: italic;
}

.changeTable {
  margin: 10px;
  font-size: 11px;
}

.ReactTable .rt-th,
.ReactTable .rt-td {
  padding: 5px !important;
}

.geometryChangeDiv {
  padding: 5px 0px;
}

.statusCheckboxContainer {
  display: inline;
  padding: 4px;
}

.statusCheckbox {
  vertical-align: middle;
}

.MuiSlider-root {}

.MuiSlider-track {
  /*display: none !important;*/
}

.showChangesDiv {}

.ZoomOutMap {
  cursor: pointer;
  font-size: 20px !important;
  padding-right: 10px;
  padding-top: 1px;
  color: #91c04d;
}

.viewTrends {
  cursor: pointer;
  font-size: 15px !important;
  color: gray;
}

.zoomOutHolder {
  vertical-align: middle;
}
.rc-slider-rail {
  position: absolute;
  width: 100%;
  background-color: #64a04085!important;
  height: 2px!important;
  border-radius: 4px!important;
  margin-top: 1px;
}
.rc-slider-track {
  position: absolute;
  left: 0;
  height: 3px;
  margin-top: 1px!important;
  border-radius: 6px;
  background-color: #659a4d!important;
}
.rc-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  cursor: pointer;
  cursor: -webkit-grab;
  margin-top: -5px;
  cursor: grab;
  border-radius: 50%;
  border: solid 2px #5d9442!important;
  background-color: white!important;
  touch-action: pan-x;
}

.rc-slider-dot {
  position: absolute;
  bottom: -1px;
  margin-left: -4px;
  width: 6px!important;
  height: 6px!important;
  border: 1px solid #a8c998!important;
  background-color: #fff!important;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
}
.rc-slider-handle:focus {
  box-shadow: 0 0 0 5px red;
  /*set !important to update */
  box-shadow: 0 0 0 0px red !important;
  /*set !important to update */
}

.rc-slider-dot-active {
  /*background-color: #96dbfa !important;*/
}

.rc-slider-handle:active {}

.activeSlider {
  border-color: red;
  box-shadow: 0 0 5px #57c5f7;
  cursor: grabbing;
}

.statsHolder {
  padding: 12px 12px 12px 12px;
  font-size: 12px;
  border-top: 1px solid #e4e8e4;
  background-color: white;
  height: 20px;
  text-align: center;
  font-feature-settings: "pcap", "c2pc";
  font-variant: all-petite-caps;
  font-weight: 700;
  color: #797471;
}

.statsBar {
  display: inline;
}
.statsHolder > span:nth-child(4) > div > div:nth-child(2) > div > span:nth-child(2){
  font-size: 15px!important;
  padding-left: 0px;
  color: #399131!important;
}
.statsHolder > span:nth-child(4) > div > div:nth-child(3) > div > span:nth-child(2){
  font-size: 15px!important;
  padding-left: 0px;
  color: #a25b28!important;
}
.statsHolder > span:nth-child(4) > div > div:nth-child(4) > div > span:nth-child(2){
  font-size: 15px!important;
  padding-left: 0px;
  color: #a6b7b9!important;
}
.statsHolder > span:nth-child(7) > div > div:nth-child(2) > div > span:nth-child(2){
  font-size: 15px!important;
  padding-left: 0px;
  color: #399131!important;
}
.statsHolder > span:nth-child(7) > div > div:nth-child(3) > div > span:nth-child(2){
  font-size: 15px!important;
  padding-left: 0px;
  color: #a25b28!important;
}
.statsHolder > span:nth-child(7) > div > div:nth-child(4) > div > span:nth-child(2){
  font-size: 15px!important;
  padding-left: 0px;
  color: #a6b7b9!important;
}
.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome and Opera */
}

.countryName {
  font-size: 14px;
  padding-right: 10px;
  /* vertical-align: middle; */
  color: #91c04d;
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(-360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

.spin {
  -webkit-animation-name: spin;
  -webkit-animation-duration: 1500ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 1500ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin;
  -ms-animation-duration: 1500ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-transition: rotate(3600deg);
}

.vMiddle {

}

.sparklineHolder {
  right: 5px;
  position: absolute;
  margin-top: 2px;
}

.statusImage {
  vertical-align: middle;
  transform: scale(0.7);
}

th {
  text-align: left !important;
  font-weight: 500 !important;
}

.wcmcChangeLog {
  display: inline;
  font-size: 10px;
  margin-top: 6px;
  position: absolute;
  left: 13px;
}
.softwareVersion {
  position: absolute;
  top: 14px;
  right: 7px;
  font-size: 14px;
  color: #91ad82;
}
.statusLabel{
  font-size:11px;
}
.wdpaAttributeTable{
  height:200px;
  overflow: auto;
}
